import { makeStyles, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import Typography from '@mui/material/Typography';
import { StyledImageField } from 'components';
import DeleteButton from 'components/DeleteButton';
import { useEffect, useState } from 'react';
import {
  Confirm,
  DateField,
  EmailField,
  Show,
  SimpleShowLayout,
  Tab,
  TabbedShowLayout,
  TextField,
  useDelete,
  useGetOne,
  useNotify,
  useRedirect,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import { StorageService } from 'services';
import { httpClient } from 'services/aws';
import { ResourceName } from 'types';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const UsersShow = () => {
  const notify = useNotify();
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const { data } = useGetOne(ResourceName.USERS, { id });
  const [apartaments, setApartaments] = useState([]);
  const [deleteOne, { isLoading }] = useDelete(
    ResourceName.USERS,
    { id },
    {
      onError: () => {
        notify('Пользователя нельзя удалить ', { type: 'error' });
      },
      onSuccess: () => {
        notify('Пользователь удален', { type: 'success' });
      },
    },
  );

  const fetchApartmentFromUser = async () => {
    const url = `admin-panel/apartment-ad/${data.id}/landlord`;
    const token = StorageService.getAccessToken();
    httpClient.setAuthorizationHeader(token || '');
    const res = await httpClient.get(url);
    setApartaments(res.data);
  };
  useEffect(() => {
    fetchApartmentFromUser();
  }, [data]);
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = async () => {
    await deleteOne();
    setOpen(false);
  };
  const classes = useStyles();

  const redirect = useRedirect();

  return (
    <Show>
      <TabbedShowLayout>
        <Tab label="О пользователе">
          <SimpleShowLayout>
            <TextField label="ID" source="id" />
            <StyledImageField $size={200} label="Аватар" source="avatarKey" />
            <TextField label="Телефон" source="phone" />
            <EmailField label="Почта" source="email" />
            <TextField label="Имя" source="firstName" />
            <TextField label="Фамилия" source="lastName" />
            <TextField label="Дата рождения" source="birthDate" />
            <TextField label="Пол" source="gender" />
            <TextField label="Статус" source="identityStatus" />
            <DateField locales="rus" showTime label="Создан" source="createdAt" />
            <DateField locales="rus" showTime label="Обновлен" source="updatedAt" />
            <Typography component="p" variant="body1">
              Контактное лицо при ЧП
            </Typography>
            <TextField label="Имя" source="guarantors[0].firstName" />
            <TextField label="Фамилия" source="guarantors[0].lastName" />
            <TextField label="Телефон" source="guarantors[0].phone" />
          </SimpleShowLayout>
          <DeleteButton text="Удалить пользователя" onClick={handleClick} />
          <Confirm
            isOpen={open}
            loading={isLoading}
            title="Удаление пользователя"
            content="Вы уверены что хотите удалить пользователя?"
            onConfirm={handleConfirm}
            onClose={handleDialogClose}
          />
        </Tab>
        <Tab label="Квартиры пользователя">
          {apartaments && apartaments.length > 0 ? (
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Изображение</TableCell>
                  <TableCell>Название</TableCell>
                  <TableCell>Период аренды</TableCell>
                  <TableCell>Тип жилья</TableCell>
                  <TableCell>Страна</TableCell>
                  <TableCell>Город</TableCell>
                  <TableCell>Создан</TableCell>
                  <TableCell>Обновлен</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {apartaments.map((row: any) => (
                  <TableRow style={{cursor: 'pointer'}} onClick={() => redirect(`/apartment-ad/${row.id}/show`)} key={row.id}>
                    <TableCell component="th" scope="row">
                      {row.media && (
                        <img
                          src={`https://d260djxm8uty7r.cloudfront.net${row.media.photos[0].fileKey}`}
                          alt="Property"
                          style={{ width: '100px' }}
                        />
                      )}
                    </TableCell>
                    <TableCell>{row.description ? row.description.name : ''}</TableCell>
                    <TableCell>{row.rentPeriodType || ''}</TableCell>
                    <TableCell>{row.apartmentType || ''}</TableCell>
                    <TableCell>{row.country || ''}</TableCell>
                    <TableCell>{row.city || ''}</TableCell>
                    <TableCell>{row.createdAt || ''}</TableCell>
                    <TableCell>{row.updatedAt || ''}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <div>У пользователя нет квартир</div>
          )}
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default UsersShow;
