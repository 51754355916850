import { ListProps } from '@material-ui/core';
import { FC } from 'react';
import { Datagrid, DateField, List, SelectInput, TextField } from 'react-admin';

const ContractsList: FC<ListProps> = (props) => {
  const statusChoices = [
    { id: 'ACCEPTED', name: 'ACCEPTED' },
    { id: 'PENDING', name: 'PENDING' },
    { id: 'REJECTED', name: 'REJECTED' },
    { id: 'CREATED', name: 'CREATED' },
    { id: 'OFFERING', name: 'OFFERING' },
    { id: 'CONCLUDED', name: 'CONCLUDED' },
    { id: 'COMPLETED', name: 'COMPLETED' },
  ];
 
  const CustomersFilter = [
    <SelectInput key="status" label="Статус" source="status" choices={statusChoices} alwaysOn />,
  ];
  return (
    <List filters={CustomersFilter}  {...props}>
      <Datagrid rowClick="show" isRowSelectable={() => false} bulkActionButtons={false}>
        <TextField label="Название" source="title" sortable={false} />
        <TextField label="Стоимость" source="cost" />
        <DateField showTime label="Дата создания" source="createdAt" />
        <DateField showTime label="Дата прибытия" source="arrivalDate" />
        <DateField showTime label="Дата отъезда" source="departureDate" />
        <TextField label="Город" sortable={false} source="address.city" />
        <TextField label="Улица" sortable={false} source="address.street" />
        <TextField label="Номер дома" sortable={false} source="address.houseNumber" />
        <TextField label="Статус" source="status" />
      </Datagrid>
    </List>
  );
};

export default ContractsList;
